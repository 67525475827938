<template>
  <div class="member-wrap container_media">
    <div class="rowShow title-row">
      <p class="card-title">我的订单</p>
      <ul class="tab-model">
        <li :class="activeName === item.value ? 'tab active' : 'tab'" v-for="(item, idx) in tabList" :key="idx"
          @click="handleTabClick(item.value)">
          <span>{{item.label}}</span>
        </li>
      </ul>
    </div>
    <div class="content">
      <div class="table-wrap" v-loading="payLoading">
        <div class="table-header">
          <div>商品信息</div>
          <div>授权信息</div>
          <div>实付款</div>
          <div>订单状态</div>
          <div>交易操作</div>
        </div>
        <div class="empty" v-if="!orderList.length">暂无数据</div>
        <div v-else class="table-items" v-for="item in orderList" :key="item.order_id">
          <div class="order-no">订单号：<span>{{item.order_no}}</span> <i></i>下单时间：{{formDateTime(item.created_at)}}</div>
          <div class="order-info">
            <div>
              <img :src="item.extend.logo" alt="">
              <p>{{item.extend.name}}</p>
            </div>
            <div>
              <p v-for="sk in item.extend.sk_id_arr" :key="sk.sk_id">{{sk.sk_name}}</p>
            </div>
            <div>￥{{Number(item.pay_amount/100)}}</div>
            <div>
              <p>订单{{item.status | transitionValToLabel(RETAIL_PAY_STATUS)}}</p>
              <span @click="handleViewDetail(item.order_id)">订单详情</span>
            </div>
            <div>
              <span v-if="item.status == 0 && item.pay_type == 'PUBLIC_PAY'">支付确认中</span>
              <span v-else :class="{'readd-btn': item.status == 4}" @click="handlePay(item)">
                {{item.status | transitionValToLabel(RETAIL_PAY_BTN)}}
              </span>
            </div>
          </div>
        </div>

      </div>
      <!-- 分页器 -->
      <div class="vf-music-pagination">
        <Pagination v-if="total > 0" :hiddenPage="true" position="center" :limit.sync="pageParams.page_size"
          :page.sync="pageParams.page" @pagination="pagination" :total="total" />
      </div>
    </div>
    <DialogForPay
      :isRetail="true"
      :dialogVisible="dialogVisiblemember"
      :payGoodInfo="payGoodInfo"
      :wxPayQrCode="wxPayQrCode"
      :aliPayQrCode="aliPayQrCode"
      @close="handlePayClose"
      @publicSubmit="handlePublicSubmit"
    ></DialogForPay>
  </div>
</template>

<script>
// import { ORDER_PAY_STATUS, VIP_TYPE } from '@/enum/common'
import Equity from '@/views/member/equity/mixins/equity'
import { feachRetailOrderList } from '@/api/member'
import Pagination from '@/components/Pagination'
import { RETAIL_PAY_STATUS, RETAIL_PAY_BTN } from "@/enum/common";
import { formDateTime } from '@/utils/formDate.js';
import { fetchMusicPayCode, fetchPayCodeResult, reAddMusicOrder, repeatMusicOrder } from '@/api/music'
import DialogForPay from '@/components/DialogForPay'
import { payReloadTime } from '@/config'

export default {
  name: 'Equity',
  components: {
    Pagination,
    DialogForPay
  },
  data() {
    return {
      payLoading: false,
      payGoodInfo: {},

      RETAIL_PAY_STATUS,
      RETAIL_PAY_BTN,
      orderList: [],
      tabList: [{
        label: `全部`,
        value: 'all'
      }, {
        label: `待支付`,
        value: '0'
      },{
        label: `已支付`,
        value: '4'
      }, {
        label: `已超时`,
        value: '2'
      }],
      retailTimer: null,
      dialogVisiblemember: false,
      wxPayQrCode: '',
      aliPayQrCode: '',
      activeName: 'all',
      pageParams: {
        page: 1,
        page_size: 20
      },
      total: 0,
    }
  },
  mixins: [Equity],
  created() {
    if (JSON.stringify(this.$route.query) !== '{}') {
      const { page, pay_status } = this.$route.query
      this.pageParams.page = Number(page)
      this.activeName = pay_status ? pay_status : 'all'
    }
    this.getOrderList()

  },
  methods: {
    formDateTime,
    pagination(info) {
      this.pageParams.page = info.page
      this.pageParams.page_size = info.limit
      this.getOrderList()
    },
    handleTabClick(v) {
      this.pageParams.page = 1
      this.activeName = v
      this.getOrderList()
    },
    // 获取订单列表
    getOrderList() {
      const params = {
        pay_status: this.activeName == 'all' ? '' : this.activeName,
        ...this.pageParams
      }
      this.$root.$goSearch(params)
      feachRetailOrderList(params).then((res) => {
        if (res.code === 0) {
          const { data } = res
          this.orderList = data.list
          this.total = data.total
        }
      })
    },
    // 跳转至详情
    handleViewDetail(id) {
      this.$store.dispatch('user/setOrderRouteInfo', this.$route.query)
      this.$router.push({
        path: `/member/center/order/detail/${id}`
      })
    },
    handlePay(e){
      this.retailOrderId = e.order_id
      this.payLoading = true
      this.payGoodInfo = {...e, price: Number(e.pay_amount / 100 )}
      switch (e.status) {
        // 待支付
        case 0:
          this.getMusicPayCode(e.order_id)
          break;
        // 已超时
        case 2:
          this.reAddOrder(e.order_id)
          break;
        // 已支付
        case 4:
          this.payLoading = false
          // this.repeatAddOrder(e.order_id)
          this.openAgain(e.extend.music_id)
          break;

        default:
          break;
      }
    },
    openAgain(id){
      let routeData = this.$router.resolve({
        path: `/music-detail/${id}`,
      })
      window.open(routeData.href, '_blank');
    },
    async reAddOrder(order_id){
      try {
        this.payLoading = true
        const res = await reAddMusicOrder({
          order_id
        })
        if (res.code === 0) {
          this.payGoodInfo = {...res.data, price: Number(res.data.pay_amount / 100 )}
          this.retailOrderId = res.data.order_id
          this.getMusicPayCode(res.data.order_id)
        }else {
          this.payLoading = false
        }
      } catch (error) {
        this.payLoading = false
        console.log(error)
      }
    },
    async repeatAddOrder(order_id){
      try {
        this.payLoading = true
        const res = await repeatMusicOrder({
          order_id
        })
        if (res.code === 0) {
          this.payGoodInfo = {...res.data, price: Number(res.data.pay_amount / 100 )}
          this.retailOrderId = res.data.order_id
          this.getMusicPayCode(res.data.order_id)
        }else {
          this.payLoading = false
        }
      } catch (error) {
        this.payLoading = false
        console.log(error)
      }
    },

    // 获取音乐零售订单
    getMusicPayCode(order_id) {
      //个人会员
      Promise.all([
        fetchMusicPayCode({order_id, pay_way:'WECHAT'}),
        fetchMusicPayCode({order_id, pay_way:'ALI'})
      ]).then((res) => {
        console.log(res);
        this.dialogVisiblemember = true
        this.payLoading = false
        this.retailOrderNO = res[0].data.order_no
        this.wxPayQrCode = res[0].data.pay_url
        this.aliPayQrCode = res[1].data.pay_url
        this.handleViewRetailOrderResult(this.retailOrderNO)
      })
    },
    // 零售订单支付结果查询
    async handleViewRetailOrderResult(order_no, name='retailTimer') {
      // 进入即刻清除定时器
      try {
        const { data } = await fetchPayCodeResult({
          order_no,
          share_token: window.localStorage.getItem('share_token')
            ? window.localStorage.getItem('share_token')
            : ''
        })
        // 判断订单的状态 -1表示订单状态不存在 0表示订单支付成功
        if (data.status == -1) {
          // 添加定时器
          this[name] = setTimeout(() => {
            this.handleViewRetailOrderResult(order_no)
          }, payReloadTime)
          return
        } else if (data.status == 0) {
          this.dialogVisiblemember = false
          this.wxPayQrCode = ''
          this.aliPayQrCode = ''
          this.handleClearCountDown(name)
          this.$router.push(`/music/order-result/${this.retailOrderId}`)
        }
        this.handleClearCountDown(name)
      } catch (e) {
        console.log(e)
        this.handleClearCountDown(name)
      }
    },
    handlePublicSubmit() {
      this.wxPayQrCode = ''
      this.aliPayQrCode = ''
      this.handlePayClose()
      this.getOrderList()
    },
    handleClearCountDown(name) {
      const timer = this[name]
      if (timer) {
        clearTimeout(timer)
        this[name] = null
      }
    },
    handlePayClose(){
      this.dialogVisiblemember = false
      this.handleClearCountDown('retailTimer')
    },
  }
}
</script>

<style lang="scss" scoped>
.table-wrap {
  width: 100%;
  .empty {
    text-align: center;
    height: 100px;
    line-height: 100px;
    color: #909399;
    background: #fff;

  }
  .table-header, .order-info{
    width: 100%;
    display: flex;
    align-items: center;
    > div:nth-child(1) {
      flex: 6;
    }
    > div:nth-child(2) {
      flex: 4;
    }
    > div:nth-child(3) {
      flex: 3;
      text-align: center;
    }
    > div:nth-child(4) {
      flex: 4;
      text-align: center;
    }
    > div:nth-child(5) {
      flex: 3;
      text-align: center;
    }
  }
  .table-header {
    background: #F5F6FA;
    border-radius: 6px;
    border: 1px solid rgba(0,0,0,.05);
    font-size: 14px;
    color: rgba(0,0,0,.5);
    padding: 0 60px;
    div{
      height: 40px;
      line-height: 40px;
      text-align: left;
    }
  }
  .table-items {
    margin-top: 16px;
    box-shadow: 0px 2px 7px 0px rgba(0,0,0,.05);
    border-radius: 8px;
    border: 1px solid rgba(0,0,0,.1);
    .order-no {
      color: #999;
      font-size: 12px;
      display: flex;
      align-items: center;
      height: 40px;
      background: #FAFAFA;
      border-radius: 8px 8px 0px 0px;
      padding-left: 60px;
      span {
        color: #333333;
      }
      i{
        display: inline-block;
        @include size(1px, 14px);
        background: #E0E0E0;
        margin: 0 32px;
      }
    }
    .order-info {
      padding: 20px 60px;
      background: #fff;
      font-size: 14px;
      font-weight: 500;
      > div:nth-child(1) {
        display: flex;
        align-items: center;
        img {
          width: 70px;
          height: 70px;
          margin-right: 20px;
        }
        p{
          width: 186px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
      > div:nth-child(3) {
        text-align: center;
      }
      > div:nth-child(4) {
        text-align: center;
        span {
          cursor: pointer;
          font-size: 12px;
          color: #999999;
        }
      }
      > div:nth-child(5) {
        text-align: center;
        span {
          cursor: pointer;
          display: inline-block;
          width: 90px;
          height: 32px;
          line-height: 32px;
          background: #E52A0D;
          border-radius: 4px;
          text-align: center;
          color: #FFFFFF;
          font-weight: 400;
        }
        .readd-btn {
          background: #F5F6FA;
          color: rgba(0,0,0,0.85);
        }
      }

    }
  }
}
.rowShow {
  display: flex;
  align-items: center;
}

.member-wrap {
  margin-top: 30px;

  .card-title {
    margin-right: 40px;
    font-size: 20px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.85);
    line-height: 20px;
  }

  .title-row {
    margin: 50px 0 40px;
  }

  .tab-model {
    display: flex;
    align-items: center;
    cursor: pointer;

    .tab {
      // width: 92px;
      padding: 0 18px;
      height: 32px;
      line-height: 32px;
      text-align: center;
      background: #FFFFFF;
      border-radius: 16px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.5);
      margin-right: 20px;
    }

    .active {
      background: rgba(229, 42, 13, 0.1);
      font-weight: 600;
      color: #E52A0D;
    }
  }

  /deep/.content {
    @include size(100%, auto);
    border-radius: 4px;
    box-sizing: border-box;
    margin-top: 24px;

    .vf-music-pagination {
      margin-top: 40px;
    }
  }
}
</style>
